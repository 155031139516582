import React, { FunctionComponent } from "react"

import "./Title.scss"

export const Title: FunctionComponent<{
  title: string
  subtitle?: string
  paragraphs?: string[]
  format: "normal" | "light"
}> = ({ title, subtitle, paragraphs, format }) => {
  const getHtml = (content: string) => ({
    __html: content,
  })
  return (
    <div className={"title --" + format}>
      <h1 dangerouslySetInnerHTML={getHtml(title)} />
      {subtitle && <h2 dangerouslySetInnerHTML={getHtml(subtitle)} />}
      {paragraphs && (
        <p key={paragraphs} dangerouslySetInnerHTML={getHtml(paragraphs)} />
      )}
    </div>
  )
}

export default Title

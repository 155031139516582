import classNames from "classnames"
import moment from "moment"
import React, { FunctionComponent, useState } from "react"
import DatePicker from "react-datepicker"
import { isMobile } from "react-device-detect"
import Arrow from "../icons/icon-arrow-right.svg"
import Chevron from "../icons/icon-chevron-left.svg"
import { IHotelConfig, IHyperlink } from "../types"
import "./BookingWidget.scss"
import { Button } from "./Button"
import Logo from "./Logo"
import Select from "react-select"

class CustomDateInput extends React.Component<any> {
  render() {
    return (
      <div className="custom-input" onClick={this.props.onClick}>
        <span>{this.props.value.split(" ")[0]}</span>{" "}
        {this.props.value.split(" ")[1]}
      </div>
    )
  }
}

interface IBookingWidgetProps {
  hotelsConfig: IHotelConfig[]
  allOffers?: IHyperlink
  compact?: boolean
  title?: string
  arriveLabel?: string
  guestLabel?: string
  departLabel?: string
  buttonLabel?: string
  hideMobile?: boolean
  maxGuests?: number
}

export const BookingWidget: FunctionComponent<IBookingWidgetProps> = ({
  hotelsConfig,
  allOffers,
  compact,
  title,
  arriveLabel = "Arriving",
  departLabel = "Departing",
  guestLabel = "Guests",
  buttonLabel = "Book now",
  hideMobile,
  maxGuests = 9,
}) => {
  const [guests, setGuests] = useState<number>(1)
  const [hotel, setHotel] = useState<string>(hotelsConfig[0].id)
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().getTime() + 86400000 * 1)
  )
  const hotelConfig: IHotelConfig | undefined = hotelsConfig.find(
    item => item.id === hotel
  )

  var disable: boolean

  if (hotel == "") {
    disable = true
  } else {
    disable = false
  }

  hotelConfig &&
    guests > (hotelConfig.maxGuests || maxGuests) &&
    setGuests(hotelConfig.maxGuests || maxGuests)
  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )

  const HiddenInputs: FunctionComponent<{ hotelConfig: IHotelConfig }> = ({
    hotelConfig,
  }) => (
    <>
      <input
        type="hidden"
        name={hotelConfig.arriveParam}
        value={startDate && moment(startDate).format(hotelConfig.dateFormat)}
      />
      <input
        type="hidden"
        name={hotelConfig.departParam}
        value={endDate && moment(endDate).format(hotelConfig.dateFormat)}
      />
      {hotelConfig.hotelParam && (
        <input type="hidden" name={hotelConfig.hotelParam} value={hotel} />
      )}
      {hotelConfig.guestsParam && (
        <input type="hidden" name={hotelConfig.guestsParam} value={guests} />
      )}
      {hotelConfig.config &&
        hotelConfig.config.map((item, index) => (
          <input key={index} type="hidden" value={item.value} name={item.key} />
        ))}
    </>
  )

  const hotelDropdownValues = []

  hotelsConfig.forEach((item, index) =>
    hotelDropdownValues.push({
      value: item.id,
      label: item.name,
      isDisabled: item.isDisabled,
    })
  )

  const guestsSelect = range(
    1,
    (hotelConfig && hotelConfig.maxGuests) || maxGuests,
    1
  ).map(i => {
    return {
      value: `${i}`,
      label: `${i}`,
      isDisabled: false,
    }
  })

  const customStyles = {
    placeholder: () => ({
      color: "var(--color-white)",
    }),
    control: () => ({
      "background-color": "transparent",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "var(--color-white)",
    }),
    menu: () => ({
      color: "var(--color-white)",
      "font-size": "1.6rem",
      position: "absolute",
      "z-index": 20,
      "background-color": "rgba(0,0,0,.9)",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    option: (provided: any, state: any) => {
      return {
        ...provided,
        "background-color": state.isFocused ? "grey" : "black",
      }
    },
  }

  const guestDropdown = {
    placeholder: () => ({
      color: "var(--color-white)",
    }),
    control: () => ({
      "background-color": "transparent",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "var(--color-black)",
      "font-size": "4rem",
      "line-height": "4.2rem",
      "font-weight": "normal",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      "justify-content": "center",
      overflow: "visible",
      "margin-top": "2.5rem",
    }),
    menu: () => ({
      color: "var(--color-black)",
      "font-size": "1.6rem",
      position: "absolute",
      "z-index": 20,
      "background-color": "var(--color-white)",
    }),
    menuList: () => ({
      width: "60px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    option: (provided: any, state: any) => {
      return {
        ...provided,
        "background-color": state.isFocused ? "grey" : "white",
        color: state.isSelected ? "black" : "black",
      }
    },
  }

  const customStyles_secondary = {
    placeholder: () => ({
      color: "#494949",
    }),
    input: () => ({
      color: "transparent",
    }),
    control: () => ({
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "row",
      fontSize: "1.4rem",
      color: "#494949",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#494949",
    }),
    menu: () => ({
      color: "var(--color-white)",
      position: "absolute",
      zIndex: 1,
      borderRadius: "5px",
      padding: "3px 0",
      backgroundColor: "#eff0f0",
      boxShadow: "0px 0px 1px 1px eff0f0",
      marginTop: "-150px",
      marginLeft: "-30px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    clearIndicator: () => ({
      backgroundColor: "red",
    }),
    option: (provided: any, state: any) => {
      let bgColor = "white"
      if (state.isFocused) bgColor = "#036cd4"
      return {
        ...provided,
        backgroundColor: state.isFocused ? "#036cd4" : "#eff0f0",
        color: "black",
        fontSize: "1.4rem",
        width: "400px",
        textAlign: "left",
        padding: "3px 0 3px 20px",
        letterSpacing: "-0.02rem",
        fontWeight: state.isSelected && "bold",
      }
    },
  }

  if (!guests) {
    setGuests(1)
  }

  return !hideMobile || !isMobile ? (
    <div
      className={classNames("booking-widget", { mobile: isMobile, compact })}
    >
      <form
        className={"booking-widget--inner"}
        action={hotelConfig && hotelConfig.postUrl}
        method="GET"
      >
        {title && <h3>{title}</h3>}
        {!isMobile && !compact && !title && (
          <Logo alt="Ovolo Hotels" color="group" />
        )}
        <div className="date">
          {hotelsConfig.length > 1 && (
            <div className="column hotels">
              {!compact && (
                <>
                  <p>Boutique Hotel</p>
                  <Select
                    className="hotel-dropdown"
                    defaultValue={"Select"}
                    styles={customStyles}
                    onChange={e => setHotel(e.value)}
                    options={hotelDropdownValues}
                  />
                  <Chevron />
                </>
              )}
            </div>
          )}
          <div className="column">
            <p>{arriveLabel}</p>
            <DatePicker
              selected={startDate}
              minDate={new Date()}
              onChange={(e: Date) => {
                setStartDate(e)
                if (endDate && e.getTime() >= endDate.getTime())
                  setEndDate(new Date(e.getTime() + 86400000 * 1))
              }}
              monthsShown={2}
              placeholderText={arriveLabel}
              dateFormat={compact ? "dd MMMM" : "MMM d"}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: "viewport",
                },
              }}
              customInput={<CustomDateInput />}
            />
          </div>
          <div className="column">
            <p>{departLabel}</p>
            <DatePicker
              selected={endDate}
              minDate={startDate || new Date()}
              onChange={(e: Date) => setEndDate(e)}
              monthsShown={2}
              placeholderText={departLabel}
              dateFormat={compact ? "dd MMMM" : "MMM d"}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: "viewport",
                },
              }}
              customInput={<CustomDateInput />}
            />
          </div>
          {!compact && (
            <div className="column">
              <p>{guestLabel}</p>
              <div className="guests">
                <button
                  type="button"
                  onClick={() => guests > 1 && setGuests(guests - 1)}
                >
                  -
                </button>
                <span className="number">{guests}</span>
                <button
                  type="button"
                  onClick={() =>
                    guests <
                      ((hotelConfig && hotelConfig.maxGuests) || maxGuests) &&
                    setGuests(guests + 1)
                  }
                >
                  +
                </button>
              </div>
            </div>
          )}
          {compact && (
            <div className="column guests">
              <p>Guests</p>
              <Select
                name="guests"
                className="guests-dropdown"
                isSearchable={false}
                styles={guestDropdown}
                defaultValue={{
                  value: "1",
                  label: "1",
                }}
                onChange={e => {
                  setGuests(e.value)
                  console.log(guests)
                }}
                options={guestsSelect}
              />
            </div>
          )}
          {compact && (
            <div className="column nights">
              <p>Nights</p>
              <span>{Math.round((+endDate - +startDate) / 86400000)}</span>
            </div>
          )}
        </div>
        {!isMobile && allOffers && (
          <a
            className="all-offers"
            href={allOffers.href}
            target={allOffers.target}
          >
            View all offers
          </a>
        )}
        {compact && (
          <div className="hotels">
            <Select
              className="hotel-dropdown"
              defaultValue={"Select"}
              styles={customStyles_secondary}
              onChange={setHotel}
              options={hotelDropdownValues}
            />
          </div>
        )}
        {hotelConfig && <HiddenInputs hotelConfig={hotelConfig} />}
        <div className="button-wrapper">
          <Button
            disabled={disable}
            type="submit"
            size={compact ? "small" : "default"}
          >
            {buttonLabel} {!compact && <Arrow />}
          </Button>
        </div>
      </form>
    </div>
  ) : (
    <></>
  )
}

export default BookingWidget

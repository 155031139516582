import React, { FunctionComponent, useEffect, useState } from "react"

import "./Sticky.scss"
import { isMobile } from "react-device-detect"
import classNames from "classnames"

export const Sticky: FunctionComponent<{
  component?: object
  backgroundImage?: string
  bgCentered?: boolean
  notop?: boolean
}> = ({ children, component, backgroundImage, notop, bgCentered }) => {
  const [imageSrc, setImageSrc] = useState(backgroundImage)
  useEffect(() => {
    backgroundImage &&
      backgroundImage.indexOf("//") === -1 &&
      import(`../images/${backgroundImage}`).then(module => {
        setImageSrc(module.default)
      })
  }, [backgroundImage])
  return (
    <div className={classNames("sticky", { mobile: isMobile }, { notop })}>
      <div
        style={{
          backgroundImage: imageSrc ? `url(${imageSrc})` : "",
          backgroundPosition: bgCentered ? "center" : "",
        }}
        className="holder"
      >
        {component}
      </div>
      <div className="posts">{children}</div>
    </div>
  )
}

export default Sticky

import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql, Link } from "gatsby"
import Booking from "../components/Booking.tsx"
import Subnavigation from "../components/Subnavigation.tsx"
import BookingWidget from "../components/BookingWidget.tsx"
import Title from "../components/Title.tsx"
import Sticky from "../components/Sticky.tsx"
import Layout from "../components/layout"
import Listing from "../components/Listing.scss"
import { Awards } from "../components/Awards.tsx"
import { Button } from "../components/Button.tsx"
import { isMobile } from "react-device-detect"
import { BOOKING_CONFIG } from "../constants"
import SEO from "../components/Seo.js"

let DESCRIPTION = {}
let SUBTITLE = {}
let TITLE = {}
let SEO_TITLE = {}
let SEO_META = {}
let BACKGROUND_IMAGE = {}
let AWARDS = []
let SUBMENU = []
let YEAR = {}
let MONTH = {}
let DAY = {}
const PERMALINK = "/awards/"

const Blog = () => (
  <StaticQuery
    query={graphql`
      query BlogAllAwardsQuery {
        allWordpressWpAwards(sort: { fields: [date], order: DESC }) {
          edges {
            node {
              date(formatString: "DD/MMM/YYYY")
              title
              slug
              link
              excerpt
              featured_media {
                localFile {
                  childImageSharp {
                    id
                    fluid(maxHeight: 275, cropFocus: NORTH) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        submenu: allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "about" } }
        ) {
          edges {
            node {
              items {
                title
                url
                target
              }
            }
          }
        }
        allWordpressAcfOptions {
          edges {
            node {
              options {
                description_awards
                background_image_awards {
                  source_url
                }
                subtitle_awards
                title_awards
                seo_title
                seo_meta_description
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        {data.allWordpressAcfOptions.edges.map(({ node }) => {
          DESCRIPTION = node.options.description_awards
          TITLE = node.options.title_awards
          SEO_TITLE = node.options.seo_title
          SEO_META = node.options.seo_meta_description
          SUBTITLE = node.options.subtitle_awards
          BACKGROUND_IMAGE = node.options.background_image_awards.source_url
        })}
        {data.submenu.edges.map(({ node }, i) => {
          SUBMENU = node.items
        })}

        <SEO title={SEO_TITLE} description={SEO_META} pathname={"/awards/"} />

        <Sticky backgroundImage={BACKGROUND_IMAGE}>
          <Subnavigation items={SUBMENU} />

          <Title title={TITLE} subtitle={SUBTITLE} paragraphs={DESCRIPTION} />

          {data.allWordpressWpAwards.edges.map(({ node }) => {
            AWARDS.push(node)
          })}

          <Awards items={AWARDS} />
        </Sticky>
      </Layout>
    )}
  />
)

export default Blog

import React, { FunctionComponent, useState } from "react"
import { Button } from "./Button"
import "./Awards.scss"
import { IAwardItem } from "../types"
import { isMobileOnly } from "react-device-detect"
import Img from "gatsby-image"
import classNames from "classnames"

export const Awards: FunctionComponent<{
  items: IAwardItem[]
}> = ({ items, children }) => {
  return (
    <div className={classNames("awards", { mobile: isMobileOnly })}>
      {items.map((item, index) => (
        <div className="award" key={index}>
          <Img fluid={item.featured_media.localFile.childImageSharp.fluid} />
          <h4 dangerouslySetInnerHTML={{ __html: item.title }} />
          {item.link && (
            <Button
              size="small"
              onClick={() =>
                item.slug ? (window.location.href = item.slug) : null
              }
            >
              {"Read More"}
            </Button>
          )}
        </div>
      ))}
    </div>
  )
}

export default Awards
